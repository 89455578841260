.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
}

.svg {
  overflow: visible;
  transform: scale(2);
}
.circle {
  animation: rotate 1.5s ease-in-out 0s infinite;
}

@keyframes rotate {
  0% {
    transform: translateX(-15px) translateY(0);
  }

  25% {
    transform: translateX(35px) translateY(0);
  }

  50% {
    transform: translateX(35px) translateY(-40px);
  }

  75% {
    transform: translateX(-15px) translateY(-40px);
  }

  100% {
    transform: translateX(-15px) translateY(0);
  }
}
